import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { AppProps } from "next/app"
import dynamic from "next/dynamic"
import { useEffect, useMemo, useState } from "react"

import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import { AppProvider, getSpatialUid, queryClientOptions, useAppContext } from "@spatialsys/web/app-context"
import { AuthContextAppProps } from "@spatialsys/web/app-state"
import { CategoriesMenu } from "@spatialsys/web/core/js/components/categories-menu/categories-menu"
import { MobileBanner } from "@spatialsys/web/core/js/components/mobile-banner/mobile-banner"
import { useScrollRestoration } from "@spatialsys/web/core/js/hooks/use-scroll-restoration"
import { h5Saga } from "@spatialsys/web/h5/h5-saga"
import { logger } from "@spatialsys/web/logger"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ClosedPlayer } from "@spatialsys/web/space/closed-player"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MiniPlayer } from "@spatialsys/web/space/mini-player"
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpaceContainerInPortal } from "@spatialsys/web/space/space-container-portals"
import type { SpacePageProps } from "@spatialsys/web/space/space-page-props"

import { BreakpointIndicatorWithState } from "./breakpoint-indicator"
import { Providers } from "./providers"

export type CustomAppProps = AppProps<AuthContextAppProps & Partial<SpacePageProps>> & {
  immediatelyOpenLogin: boolean
}

const GlobalModals = dynamic(
  () =>
    import(
      /* webpackChunkName: "global-modals" */ "@spatialsys/web/core/js/components/global-modals/global-modals"
    ).then((mod) => mod.GlobalModals),
  { ssr: false }
)
const PageTransitionLoader = dynamic(
  () =>
    import(
      /* webpackChunkName: "page-transition-loader" */ "@spatialsys/web/core/js/components/page-transition-loader/page-transition-loader"
    ).then((mod) => mod.PageTransitionLoader),
  { ssr: false, loading: () => null }
)
const AdinPlayAdsContainer = dynamic(
  () =>
    import(/* webpackChunkName: "adinplay-ads-container" */ "@spatialsys/web/adinplay/video-ads-container").then(
      (mod) => ({
        default: mod.VideoAdsContainer,
      })
    ),
  { ssr: false }
)
const ImaAdsContainer = dynamic(
  () =>
    import(/* webpackChunkName: "ima-ads-container" */ "@spatialsys/web/ima/video-ads-container").then((mod) => ({
      default: mod.VideoAdsContainer,
    })),
  { ssr: false }
)
const WalmartBanner = dynamic(
  () =>
    import(
      /* webpackChunkName: "walmart-banner" */ "@spatialsys/web/core/js/components/walmart-banner/walmart-banner"
    ).then((mod) => mod.WalmartBanner),
  { ssr: false }
)

/**
 * Pages router provider for react-query, supporting server-side rendering with hydration.
 *
 * We must create the query client INSIDE the app, per https://tanstack.com/query/v4/docs/guides/ssr#using-hydration,
 * to support SSR with hydration.
 */
export const ReactQueryProvider = ({ children, pageProps }: React.PropsWithChildren<{ pageProps: any }>) => {
  const [queryClient] = useState(() => new QueryClient(queryClientOptions))

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>{children}</Hydrate>
    </QueryClientProvider>
  )
}

/**
 * Skeleton that renders the app layout including global components such as modals
 * for the page router
 */
export function AppSkeleton({ Component, pageProps }: CustomAppProps) {
  // If the server-side render of the page included `spatialUid` read from the request cookie,
  // use that. Otherwise, read the value from the variable that should have been set by the
  // `setSpatialUid` call above.
  const spatialUid = useMemo(() => pageProps.spatialUid ?? getSpatialUid(), [pageProps.spatialUid])

  useScrollRestoration()

  return (
    <ReactQueryProvider pageProps={pageProps}>
      <Providers>
        <AppProvider {...pageProps} spatialUid={spatialUid}>
          <ReactQueryDevtools position="top-left" />
          <Component {...pageProps} />
          <CategoriesMenu />
          <MobileBanner />
          <GlobalModals />
          <PageTransitionLoader />
          <SpaceContainerInPortal />
          <ClosedPlayer />
          <MaybeMiniPlayer />
          <AdinPlayAdsManager />
          <BreakpointIndicatorWithState />
          <ImaAdsManager />
          <H5AdsManager />
          <WalmartBanner />
        </AppProvider>
      </Providers>
    </ReactQueryProvider>
  )
}

function MaybeMiniPlayer() {
  const mode = useAppContext((context) => context.state.canvas.mode)
  if (mode !== "miniplayer") {
    return null
  }
  return <MiniPlayer />
}

function AdinPlayAdsManager() {
  const ffQuery = useGetFeatureFlagsQuery()
  const embedSource = useAppContext((context) => context.state.canvas.embedSource)

  // Load AdinPlay SDK if feature flag is enabled, and not on embedded.
  //  TODO (DEV-30057): consider ads behaviour on embed
  if (
    ffQuery.data?.featureFlags.adinPlayAds &&
    !ffQuery.data?.featureFlags.gamAds &&
    !ffQuery.data?.featureFlags.h5Ads &&
    !embedSource
  )
    return <AdinPlayAdsContainer />

  return null
}

function ImaAdsManager() {
  const ffQuery = useGetFeatureFlagsQuery()
  const embedSource = useAppContext((context) => context.state.canvas.embedSource)

  if (ffQuery.data?.featureFlags.gamAds && !ffQuery.data?.featureFlags.h5Ads && !embedSource) return <ImaAdsContainer />
}

function H5AdsManager() {
  const ffQuery = useGetFeatureFlagsQuery()
  const embedSource = useAppContext((context) => context.state.canvas.embedSource)
  const runSaga = useAppContext((context) => context.runSaga)
  const isUsingH5 = ffQuery.data?.featureFlags.h5Ads && !embedSource

  useEffect(() => {
    if (isUsingH5) {
      const handleError = (err: Error, errInfo: any) => {
        logger.error("h5Saga crashed", err, errInfo)
      }
      const task = runSaga({ onError: handleError }, h5Saga)
      return task.cancel
    }
  }, [runSaga, isUsingH5])

  return null
}
