import type { QueryClientConfig } from "@tanstack/react-query"
import { AxiosError } from "axios"

export const queryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
}

/**
 * Default options for the query client on the server.
 *
 * Sets the default `retry` to 3, but only retries on non 404 errors.
 */
export const queryClientServerOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error instanceof AxiosError && error.response?.status === 404) {
          return false
        }
        return failureCount < 3
      },
      useErrorBoundary: true,
    },
  },
  // Custom loggers will be deprecated in react-query v5, but failed queries will also
  // no longer be logged. Can remove once v5 is out and we've migrated.
  logger: {
    log: console.log,
    warn: console.warn,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    error: () => {},
  },
}

export * from "./react-query-context"
