import { UseQueryOptions, useQuery } from "@tanstack/react-query"

import { SpaceLimitsResponse } from "@spatialsys/js/sapi/types"

import { useSapi } from "../use-sapi"

export const GET_SPACE_LIMITS_QUERY_KEY = "GET_SPACE_LIMITS"

const cachingOptions = {
  retry: 1,
  cacheTime: 60 * 1000, // 1 minute
  staleTime: 15 * 1000, // 15 seconds
}

export const useGetSpaceLimitsQuery = (
  spaceId: string | null | undefined,
  options?: UseQueryOptions<SpaceLimitsResponse, unknown, SpaceLimitsResponse, string[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: [GET_SPACE_LIMITS_QUERY_KEY, spaceId] as [typeof GET_SPACE_LIMITS_QUERY_KEY, string],
    queryFn: () => sapiClient.spacesV2.getSpaceLimits(spaceId as string),
    enabled: Boolean(spaceId),
    ...cachingOptions,
    ...options,
  })
}
