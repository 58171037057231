import { call, delay, put } from "typed-redux-saga/macro"

import { Actions, AuthProvider, SetAuth } from "@spatialsys/web/app-state"
import { logger } from "@spatialsys/web/logger"
import { swapiClient } from "@spatialsys/web/sapi"

import { createAndFormatAuthState, logout } from "../auth"
import { AuthLogChannel } from "../log-channel"

const OFFSET_MS = 3 * 1000 * 60 // 3 minutes

/**
 * Schedules a timer to refresh our auth token.
 * We store our accessToken in global state, and don't call Firebase on every API call to fetch a token (like most guides would recommend)
 * Instead, schedule a timer to refresh the token {@link OFFSET_MS} minutes prior to expiry.
 */
export function* scheduleRefreshSession({ payload: authState }: SetAuth) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const timeout = yield* call(getTimeoutWithOffset, authState.expiresAt!)
  yield* delay(timeout)
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newAuthState = yield* call(refreshSession, authState.provider!)
    yield* put(Actions.setAuthSuccess(newAuthState)) // Dispatching this action will re-schedule a new renewal!
  } catch {
    // Unable to refresh for some reason, log the user out. Errors are logged in `refreshSession`
    yield* call(logout)
  }
}

/**
 * Attempts to renew (refresh) the auth session by refreshing the existing token
 * via our Next.js API.
 */
export function* refreshSession(authProvider: AuthProvider) {
  logger.info("Renewing auth session")
  try {
    const { idToken, expiresAt } = yield* call(swapiClient.authSession.refresh)
    return createAndFormatAuthState(idToken, expiresAt, false, AuthProvider.Firebase)
  } catch (error: any) {
    logger.error(AuthLogChannel, "Error attempting to renew session", error, { authProvider })
    throw error
  }
}

/**
 * Calculates how long to set the timeout for, with an offset
 * so that we refresh before the token before it actually expires
 */
export function getTimeoutWithOffset(expiresAtInMsFromEpoch: number) {
  const now = Date.now()
  const expiresIn = expiresAtInMsFromEpoch - now

  if (expiresIn <= 0) {
    // This should be impossible, log this error so that it's tracked.
    logger.error(AuthLogChannel, `Unable to schedule refresh, got token that expires too soon.`, {
      expiresAt: expiresAtInMsFromEpoch,
    })
    return 0 // Return 0 to refresh immediately
  }

  // Refresh the token OFFSET_MS before it expires
  const timeout = Math.max(0, expiresIn - OFFSET_MS)
  return timeout
}
